@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-btn {
  @at-root #{&}-tertiary-icon {
    width: 3rem;
  }
  @at-root #{&}-tertiary {
    i,
    span {
      z-index: 2;
    }
    &:hover::after,
    &:focus::after {
      opacity: 1;
      transform: scale(1, 1);
      border-radius: inherit;
    }
    &:hover::after {
      background: var(--gray-200);
    }
    &:active::after {
      background: var(--gray-0);
    }
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: scale(0, 0);
      border-radius: 50%;
      transition: 0.35s ease-in-out;
      opacity: 0;
    }
  }
  .spinner-border {
    position: absolute;
    z-index: 3 !important;
  }
}
