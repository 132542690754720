@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.sz-Calendar {
  padding: 25px 40px;
  .fc-button-primary {
    background: var(--secondary);
    border-radius: 0;
    border: 0;
  }
  .fc-next-button {
    position: absolute;
    right: 0;
    top: 15px;
  }
  .fc-prev-button {
    position: absolute;
    left: 0;
    top: 15px;
  }
  .fc-toolbar.fc-header-toolbar {
    margin: 0;
    height: 0;
  }
  td.fc-today {
    background: white;
  }
  .fc-day {
    border-right: 1px solid var(--btn-disable) !important;
    &:last-child {
      border-right: 0;
    }
  }

  .fc th,
  .fc td {
    border: 0;
  }
}
