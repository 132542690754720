.RRT__container {
  width: 100%;
  .RRT__tabs {
    font-size: 18px;
    border: 0px;
    margin-bottom: 20px;
    border: none;
    .RRT__tab {
      border: 0px;
      color: var(--gray-light);
      background-color: transparent;
      &.RRT__tab--selected {
        border: 0px;
        border-bottom: 2px solid var(--dark);
        color: var(--dark);
        background-color: var(--gray-0);
      }
    }
  }
  .RRT__panel {
    border: 0px;
    padding: 0px;
  }
  .RRT__accordion {
    .RRT__tab--collapsed {
      margin-bottom: 20px;
    }
  }
}
