@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-button-group {
  @at-root #{&}__btn-choose {
    background: #ffffff !important;
    font-size: $h1-font-size;
    border: 2px solid var(--light) !important;
    width: 50%;
    height: 58px;
    box-shadow: none;
    flex: none !important;
    &.active,
    &:hover,
    &.focus {
      border-color: var(--secondary) !important;
      background: var(--light) !important;
      color: var(--secondary) !important;
      box-shadow: none !important;
    }
  }
  @at-root #{&}__btn-choose_icon {
    width: 58px;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--light);
  }
  @at-root #{&}__btn-choose.active #{&}__btn-choose_icon {
    background: var(--light);
  }
}
