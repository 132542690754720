.arrows {
  margin-top: 5%;
}
.sz-carousel {
  @at-root #{&}__container {
    position: relative;
  }
  @at-root #{&}__container .rec-carousel-item {
    border-radius: 4px;
  }
  @at-root #{&}__container .rec-carousel-item > div {
    display: block;
  }
  @at-root #{&}__img {
    width: 200px;
    transition: 0.5s;
    &:hover {
      -webkit-filter: brightness(0.5);
      filter: brightness(0.5);
    }
  }
  @at-root #{&}__full {
    border-radius: 10px;
    overflow: hidden;
  }
  @at-root #{&}__full-content {
    border-radius: 10px;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .rec-carousel {
    .rec-item-wrapper {
      padding: 0 !important;
    }
  }
  .rec-pagination {
    position: absolute;
    bottom: 10px;
    .rec-dot {
      border: white 1px solid;
      width: 10px;
      height: 15px;
    }
    .rec-dot_active {
      background: #f8f9fab8;
      box-shadow: none;
    }
  }
}
