.sz-notification {
  max-width: 45px;
  @at-root #{&}-icon {
    font-size: 2rem;
  }
  @at-root #{&}-number {
    padding: 1px 3px;
    right: 2px;
    top: 0;
    min-width: 16px;
  }
}
