@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-modal {
  @at-root #{&} #{&}_header {
    padding-top: 2.5rem;
  }
  @at-root #{&} #{&}_close {
    top: 1rem;
    right: 0.5rem;
    .sz-icon-line.close {
      padding: 0;
      margin: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .sz-modal {
    @at-root #{&} #{&}_close {
      top: 1rem;
      right: 2rem;
    }
  }
}
