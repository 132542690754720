@import '../../../assets/scss/1-settings/1-settings.scss';

.sz-daterangepicker {
  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-clear-button {
    display: none;
  }
  .react-calendar {
    padding: 1rem 0.5rem;
    border-color: var(--grey);
    z-index: 1;
    .react-calendar__month-view__weekdays {
      &__weekday {
        font-size: 1rem;
      }
    }
    .react-calendar__navigation {
      background-color: var(--gray-0);
      margin: 0 0.3rem 1rem;
      &__arrow {
        font-size: 1rem;
      }
      &__next2-button,
      &__prev2-button {
        display: none;
      }
    }
    .react-calendar__month-view__days__day.react-calendar__tile {
      margin: 0.45rem 0;
      border-top: 1px solid var(--white);
      border-bottom: 1px solid var(--white);
      &--active,
      &--active:enabled:hover,
      &--active:enabled:focus,
      &:enabled:hover,
      &:enabled:focus,
      &:hover {
        &.react-calendar__tile--rangeStart,
        &.react-calendar__tile--rangeEnd {
          background-color: #bbdbfd;
          border-radius: 2px;
        }
        background-color: var(--gray-200);
        color: var(--primary-dark);
      }
      &--now {
        background-color: var(--white);
        border-radius: 2px;
        border: 1px solid var(--blue-3);
      }
    }
    .react-calendar__month-view__days__day--weekend {
      color: var(--grey);
    }
    .react-calendar__month-view__weekdays__weekday {
      visibility: hidden;
      color: var(--gray);
      &::first-letter {
        visibility: visible;
      }
      abbr {
        text-decoration: none;
        padding-left: $default-spacing * 2;
      }
    }
    .react-calendar__month-view {
      padding: 0 $default-spacing * 2;
      font-family: $font-family-sans-serif;
    }
    .react-calendar__year-view__months {
      justify-content: center;
      .react-calendar__tile {
        border: 2px solid var(--gray-200);
        border-radius: 2px;
        text-transform: capitalize;
        &--hover {
          background: var(--gray-200);
        }
        &--active.react-calendar__tile--now,
        &--active {
          border: 2px solid var(--primary-dark);
          background-color: var(--gray-200);
          color: var(--primary-dark);
        }
        &--now {
          background-color: var(--white);
          border-radius: 2px;
        }
        &--now.react-calendar__tile--hover,
        &--now.react-calendar__tile--active {
          background-color: var(--gray-200);
        }
      }
      &__month {
        flex-basis: 31% !important;
        margin: 0.3rem 0.2rem;
        padding: 1.5rem 0;
        font-family: $font-family-bold;
        font-weight: bold;
      }
    }
  }
}
