@import '../../../assets/scss/1-settings/1-settings.scss';

.sz-datepicker {
  @at-root #{&}__single,
    #{&}__start,
    #{&}__end {
    &::before {
      position: absolute;
      bottom: 0.5rem;
      padding-left: 1rem;
      color: var(--blue-2);
    }
  }
  @at-root #{&}__single {
    &::before {
      content: 'Le';
    }
  }
  @at-root #{&}__start {
    &::before {
      content: 'Du';
    }
  }
  @at-root #{&}__end {
    &::before {
      content: 'Au';
    }
  }
}

.react-date-picker {
  height: 3rem;
  width: 50%;
  @at-root #{&}__wrapper {
    border: 1px solid var(--btn-disabled);
    width: 100%;
    padding-left: 2.5rem;
    .react-date-picker__calendar-button {
      display: flex;
    }
  }
  input {
    color: var(--secondary);
    font-family: $font-family-regular;
    font-size: 1em;
    &:focus {
      outline: none;
    }
  }
  @at-root #{&}__button {
    padding: 4px 15px;
  }
  @at-root #{&}__inputGroup {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
  }
  @at-root #{&}__calendar {
    z-index: 6;
    .react-calendar {
      padding: $default-spacing $default-spacing $default-spacing * 3;
      border-color: var(--grey);
      &:after,
      &:before {
        bottom: 99.8%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
      }
      &:before {
        border-color: rgba(204, 204, 204, 0);
        border-bottom-color: var(--grey);
        border-width: 11px;
        margin-left: -11px;
      }
    }
  }
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button,
  .react-calendar__tile--hasActive {
    background: var(--primary-brand);
  }
  .react-calendar__tile--active {
    background: var(--white);
    border: 1px solid var(--primary-brand);
    color: var(--secondary);
    &:hover {
      background: var(--primary-brand);
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: var(--grey);
  }
  .react-calendar__month-view__weekdays__weekday {
    visibility: hidden;
    color: var(--gray);
    &::first-letter {
      visibility: visible;
    }
    abbr {
      text-decoration: none;
      padding-left: $default-spacing * 2;
    }
  }
  .react-calendar__month-view {
    padding: 0 $default-spacing * 2;
    font-family: $font-family-sans-serif;
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }
}
