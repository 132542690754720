.sz-radio {
  .custom-control-label {
    &:before {
      border-radius: 50% !important;
      background: transparent !important;
      width: 17px;
      height: 17px;
      top: 30%;
    }
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background: var(--primary) !important;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    left: -20px;
    top: 18px;
  }
}
