@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-accordion {
  .sz-collapse {
    &.card {
      overflow: inherit;
    }
    @at-root #{&}__header {
      line-height: 1;
      box-shadow: 0 0 1px 0 var(--blue-1);
    }
    @at-root #{&}__footer.card-footer {
      border-top: $border-width solid var(--gray-0);
    }
    box-shadow: 0 0 1px 0 var(--blue-1);
  }
}
