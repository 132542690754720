@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-form-group {
  width: 100%;
  margin-bottom: 0.5rem;
  @at-root #{&}__label {
    font-size: 0.875rem;
    color: var(--blue-2);
  }
  @at-root #{&}__input.form-control {
    padding-top: 0.25rem;
    &:focus {
      border-color: var(--blue-2) !important;
    }
    &::placeholder {
      font-family: $font-family-light;
    }
  }
  @at-root #{&}__input-icon,
    #{&}__input-icon-info,
    #{&}__input-icon-valid,
    #{&}__input-icon-clear,
    #{&}__input-icon-show {
    z-index: 5;
    top: 1px;
    bottom: 1px;
    color: var(--blue-2);
  }
  @at-root #{&}__input-icon {
    left: 1rem;
  }
  @at-root #{&}__input-icon-show,
    #{&}__input-icon-valid,
    #{&}__input-icon-clear,
    #{&}__input-icon-info {
    right: 1px;
  }
}
