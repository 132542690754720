@import '../../../assets/scss/1-settings/1-settings';

.react-bootstrap-table {
  overflow-x: auto;
}
.sz-table {
  table-layout: inherit !important;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  white-space: nowrap;
  tr td:first-child,
  tr th:first-child {
    padding-left: 1.2rem;
  }
  th:focus {
    outline: none;
  }
  &.sticky.ended {
    thead tr th:last-child,
    tbody tr td:last-child {
      box-shadow: none;
    }
  }
  &.sticky {
    padding-right: 4.5rem;
    thead tr th:last-child {
      background: var(--blue-clear-light);
    }
    tbody tr td:last-child {
      background: var(--primary-light);
    }
    thead tr th:last-child,
    tbody tr td:last-child {
      box-shadow: -3px 0px 3px 0px rgba(0, 0, 0, 0.1);
      position: absolute;
      right: 0;
      padding: 0 0.6rem;
      width: 5.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @at-root #{&} #{&}-header {
    height: 48px;
    background: var(--blue-clear-light);
    color: var(--inactive);
    th {
      font-weight: $font-weight-normal;
      padding: 0;
      padding-left: 0.75rem;
      vertical-align: middle;
      border: none;
      span {
        color: var(--secondary);
      }
    }
  }
}
