.sz-list {
  @at-root #{&}.list-group-horizontal #{&}_item.list-group-item {
    &.active {
      background: none;
      border: none;
      color: var(--text-primary-dark);
      margin-left: 0;
    }
    &:hover {
      .sz-list_item-border {
        left: 0 !important;
        right: 0 !important;
      }
    }
    .sz-list_item-border {
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: black;
    }
  }
}
